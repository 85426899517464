<template>
  <v-container fluid class="admin-pages">
    <PageTitle
      icon="mdi-account-supervisor"
      main="Administração do Sistema"
      sub="Cadastros & Cia"
    />

    <div class="admin-pages-tabs">
      <v-tabs v-model="tabs" background-color="primary" dark>
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#artigos">
          <v-icon class="pa-1">mdi-form-select</v-icon>
          <span class="d-none d-lg-inline">Artigos</span>
        </v-tab>

        <v-tab href="#categorias">
          <v-icon class="pa-1">mdi-file-tree-outline</v-icon>
          <span class="d-none d-lg-inline">Categorias</span>
        </v-tab>

        <v-tab href="#usuarios" >
          <v-icon class="pa-1">mdi-account-multiple</v-icon>
          <span class="d-none d-lg-inline">Usuários</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <v-tab-item :key="1" value="artigos">
          <v-card flat>
            <v-card-text><ArticleAdmin /></v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="2" value="categorias">
          <v-card flat>
            <v-card-text ><CategoryAdmin /></v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="3" value="usuarios">
          <v-card flat>
            <v-card-text><UserAdmin /> </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-container>
</template>

<script>
import PageTitle from "@/layouts/PageTitle.vue";
import ArticleAdmin from "@/views/admin/ArticleAdmin.vue";
import CategoryAdmin from "@/views/admin/CategoryAdmin.vue";
import UserAdmin from "@/views/admin/UserAdmin.vue";

export default {
  name: "AdminPages",
  components: { PageTitle, ArticleAdmin, CategoryAdmin, UserAdmin },
  data() {
    return {
      tabs: null,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },
  mounted() {
    this.$store.state.isMenuVisibleMobile = true;
    
  },
};
</script>

<style>


</style>

