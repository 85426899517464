<template>
  <div class="category-admin " >
    <v-form >
      <input id="category-id" type="hidden" v-model="category.id" />
      <v-row  >
        <v-col cols="12" class="pa-3" >
          <v-text-field ref="textName"
            v-model="category.name"
            label="Nome"
            required
            :readonly="mode === 'remove'"
            outlined
            hide-details="auto"

          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pa-3">
          <v-select
            :items="categories"
            v-model="categoryParentId"
            label="Categoria Pai:"
            outlined
            hide-details="auto"


          />
          <!-- <v-combobox
            v-if="mode === 'save'"
            clearable
            v-model="categoryParentId"
            :item-text="category.path"
            :item-value="category.path"
            label="Categoria Pai:"
            :items="categories"
          ></v-combobox>
          <v-combobox
            v-else
            label="Categoria Pai:"
            v-model="categoryParentId"
            readonly
            >category.parentId</v-combobox
          > -->
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6" sm="12">
          <v-btn color="primary" v-if="mode === 'save'" @click="save" ref="btnSalvar">
            Salvar
          </v-btn>
          <v-btn color="error" v-if="mode === 'remove'" @click="remove">
            Excluir
          </v-btn>
          <v-btn class="ml-2" @click="reset"> Cancelar </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <hr class="mt-3" />

    <v-data-table
      :headers="headers"
      :items="categories"
      item-key="id"
      class="elevation-1 pt-3"
    >
      <template
        v-for="header in headers.filter((header) =>
          header.hasOwnProperty('formatter')
        )"
        v-slot:[`item.${header.value}`]="{ header, value }"
      >
        {{ header.formatter(value) }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn elevation="2" icon @click="loadcategory(item)" class="mr-2"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
        <v-btn elevation="2" icon @click="loadcategory(item, 'remove')" class=""
          ><v-icon>mdi-delete</v-icon></v-btn
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { baseApiUrl, showError, showSucess } from "@/global";
import axios from "axios";


export default {
  name: "category-admin",

  data: function () {
    return {
      mode: "save",
      category: {},
      categoryParentId: null,
      categories: [],
      booleanYes: Boolean,
      headers: [
        {
          text: "Código",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Caminho",
          align: "start",
          sortable: true,
          value: "path",
        },
        {
          text: "Acões",
          align: "start",
          value: "action",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    loadCategories() {
      const url = `${baseApiUrl}/categories`;
      axios.get(url).then((res) => {
        // this.categories = res.data
        this.categories = res.data.map((category) => {
          return { ...category, value: category.id, text: category.path };
        });
      });
    },
    reset() {
      this.mode = "save";
      this.category = {};
      this.categoryParentId = null;
      this.loadCategories();
    },
    save() {
      const method = this.category.id ? "put" : "post";
      const id = this.category.id ? `/${this.category.id}` : "";
      if (this.categoryParentId !== null) {
        this.category.parentId = this.categoryParentId.id;
        console.log(this.categoryParentId.id);
      }
      console.log(this.category);
      axios[method](`${baseApiUrl}/categories${id}`, this.category)
        .then(() => {
          showSucess();
          this.reset();
        })
        .catch(showError);
    },
    remove() {
      const id = this.category.id;
      axios
        .delete(`${baseApiUrl}/categories/${id}`)
        .then(() => {
          showSucess();
          this.reset();
        })
        .catch(showError);
    },
    loadcategory(category, mode = "save") {
      this.mode = mode;
      this.category = {
        // ...category,
        id: category.id,
        name: category.name,
        parentId: category.parentId,
      };
      this.categoryParentId = null;
      this.categories.forEach((element) => {
        if (element.id == this.category.parentId) {
          this.categoryParentId = element;
        }
      });
      this.$refs.textName.focus();
      //   this.categorySelect = this.category;
    },
  },
  mounted() {
    this.loadCategories();
  },
};
</script>

<style>
</style>

