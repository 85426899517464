<template>
  <BaseCard :main="main" :icon="icon" :sub="sub"> </BaseCard>
</template>

<script>
export default {
  name: "PageTitle",
  props: ["icon", "main", "sub"],
};
</script>

<style>
#titulo {
  width: 100%;
}
.v-card {
  border: none;
  border-color: brown;
  border-radius: none;
}

.texto {
  vertical-align: bottom;
}
</style>