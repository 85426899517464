<template>
  <div class="article-admin">
    <v-form>
      <input id="article-id" type="hidden" v-model="article.id" />
      <v-row>
        <v-col cols="12" class="pa-3">
          <v-text-field
            ref="textName"
            v-model="article.name"
            label="Nome"
            required
            :readonly="mode === 'remove'"
            outlined
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pa-3" >
          <v-text-field
            v-model="article.description"
            label="Descrição"
            required
            :readonly="mode === 'remove'"
            outlined
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pa-3" v-if="mode === 'save'">
          <v-text-field
            ref="textName"
            v-model="article.imageUrl"
            label="Imagem (URL)"
            required
            :readonly="mode === 'remove'"
            outlined
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="pa-3" v-if="mode === 'save'">
          <v-select
            :items="categories"
            v-model="article.categoryId"
            :item-value="categories.path"
            label="Categoria:"
            outlined
            hide-details="auto"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pa-3" v-if="mode === 'save'">
          <v-select
            :items="users"
            v-model="article.userId"
            label="Autor:"
            outlined
            hide-details="auto"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="pa-3" v-if="mode === 'save'">
          <VueEditor id='editor'
            v-model="article.content"
            placeholder="Informe o Conteúdo do Artigo..."
            style="--placeholder-color: red "
            
          />

          <!-- <v-card 
            hover
            
            elevation="0"
            class="mx-auto"
            style="border: 1px solid rgba(0, 0, 0, .38);"
            outlined >
              <v-subheader class="pa-3 font-weight-bold" style="font-size: 16px"
            >Conteúdo</v-subheader>  
            <VueEditor
              v-model="article.content"
              placeholder="Informe o Conteúdo do Artigo..."
            />
          </v-card> -->
          <!-- Use the component in the right place of the template -->
          <!-- <tiptap-vuetify v-model="content" :extensions="extensions" /> -->
          <!-- <v-subheader class="pa-3 font-weight-bold" style="font-size: 16px"
            >Conteúdo</v-subheader
          > -->
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6" sm="12">
          <v-btn
            color="primary"
            v-if="mode === 'save'"
            @click="save"
            ref="btnSalvar"
          >
            Salvar
          </v-btn>
          <v-btn color="error" v-if="mode === 'remove'" @click="remove">
            Excluir
          </v-btn>
          <v-btn class="ml-2" @click="reset"> Cancelar </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <hr class="mt-3" />

    <v-data-table
      :page="page"
      :pageCount="numberOfPages"
      :options.sync="options"
      :server-items-length="count"
      :loading="loading"

      :headers="headers"
      :items="articles"
      item-key="id"
      class="elevation-1 pt-3"
      
      :footer-props="{
           'items-per-page-text':'Artigos por página',
           pageText: '{0}-{1} de {2}'
      }"

    >
      <template
        v-for="header in headers.filter((header) =>
          header.hasOwnProperty('formatter')
        )"
        v-slot:[`item.${header.value}`]="{ header, value }"
      >
        {{ header.formatter(value) }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn elevation="2" icon @click="loadArticle(item)" class="mr-2"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
        <v-btn elevation="2" icon @click="loadArticle(item, 'remove')" class=""
          ><v-icon>mdi-delete</v-icon></v-btn
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { baseApiUrl, showError, showSucess } from "@/global";
import axios from "axios";

export default {
  name: "ArticleAdmin",
  components: { VueEditor },
  data: function () {
    return {
      mode: "save",
      article: {},
      articleParentId: null,
      articles: [],
      categories: [],
      users: [],
      page: 1,
      numberOfPages: 1,
      limit: 0,
      count: 0,
      loading: true,
      options: {},
      booleanYes: Boolean,
      headers: [
        {
          text: "Código",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Descrição",
          align: "start",
          sortable: true,
          value: "description",
        },
        {
          text: "Acões",
          align: "start",
          value: "action",
          sortable: false,
        },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.loadArticles();
      },
    },
    deep: true,
  },
  methods: {
    loadArticles() {
      this.loading = true;
      this.page =this.options.page;
      const url = `${baseApiUrl}/articles?page=${this.page}`;
      axios.get(url).then((res) => {
        this.articles = res.data.data;
        this.count = res.data.count;
        this.limit = res.data.limit;
        if ( (this.count % this.limit) > 0 ) {
          this.numberOfPages = Math.trunc(res.data.count/10) + 1          
        } else {
          this.numberOfPages = Math.trunc(res.data.count/10)        
        }
        this.loading = false;
        
      });
    },
    reset() {
      this.mode = "save";
      this.article = {};
      this.loadArticles();
    },
    
   save() {
            const method = this.article.id ? 'put' : 'post'
            const id = this.article.id ? `/${this.article.id}` : ''
            axios[method](`${baseApiUrl}/articles${id}`, this.article)
                .then(() => {
                    showSucess();
                    this.reset()
                })
                .catch(showError)
    },    
    
    
    remove() {
      const id = this.article.id;
      axios
        .delete(`${baseApiUrl}/articles/${id}`)
        .then(() => {
          showSucess();
          this.reset();
        })
        .catch(showError);
    },
    loadArticle(article, mode = "save") {
      this.mode = mode;
      axios
        .get(`${baseApiUrl}/articles/${article.id}`)
        .then((res) => (this.article = res.data));
    },
    loadCategories() {
      const url = `${baseApiUrl}/categories`;
      axios.get(url).then((res) => {
        this.categories = res.data.map((category) => {
          return { value: category.id, text: category.path };
        });
      });
    },
    loadUsers() {
      const url = `${baseApiUrl}/users`;
      axios.get(url).then((res) => {
        this.users = res.data.map((user) => {
          return { value: user.id, text: `${user.name} - ${user.email}` };
        });
      });
    },
  },
  mounted() {
    this.loadArticles();
    this.loadCategories();
    this.loadUsers();
  },
};
</script>

<style>


</style>


