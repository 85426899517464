<template>
  <div class="user-admin">
    <v-form>
      <input id="user-id" type="hidden" v-model="user.id" />
      <v-row>
        <v-col cols="12" md="6" sm="12">
          <v-text-field
            v-model="user.name"
            label="Nome"
            required
            outlined
            :rules="rules"
            hide-details="auto"
            :readonly="mode === 'remove'"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <v-text-field
            v-model="user.email"
            label="E-mail"
            required
            :rules="rulesEmail"
            hide-details="auto"
            :readonly="mode === 'remove'"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-checkbox
        id="user-admin"
        v-model="user.admin"
        v-show="mode === 'save'"
        class="mt-3"
        label="Usuário Adminstrador ?"
      ></v-checkbox>
      <v-row v-show="mode === 'save'">
        <v-col cols="12" md="6" sm="12">
          <v-text-field
            v-model="user.password"
            label="Senha:"
            required
            type="password"
            outlined
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <v-text-field
            v-model="user.confirmPassword"
            label="Confirmação de Senha :"
            required
            type="password"
            outlined
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" sm="12">
          <v-btn color="primary" v-if="mode === 'save'" @click="save">
            Salvar
          </v-btn>
          <v-btn color="error" v-if="mode === 'remove'" @click="remove">
            Excluir
          </v-btn>
          <v-btn class="ml-2" @click="reset"> Cancelar </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <hr class="mt-3" />

    <v-data-table
      :headers="headers"
      :items="users"
      item-key="name"
      class="elevation-1 pt-3"
    >
      <template
        v-for="header in headers.filter((header) =>
          header.hasOwnProperty('formatter')
        )"
        v-slot:[`item.${header.value}`]="{ header, value }"
      >
        {{ header.formatter(value) }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn elevation="2" icon @click="loadUser(item)" class="mr-2"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
        <v-btn elevation="2" icon @click="loadUser(item, 'remove')" class=""
          ><v-icon>mdi-delete</v-icon></v-btn
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { baseApiUrl, showError, showSucess } from "@/global";
import axios from "axios";

export default {
  name: "UserAdmin",
  data: function () {
    return {
      rules: [
        (value) => !!value || "Campo obrigatório",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
      ],
      rulesEmail: [
        (value) => !!value || "Campo obrigatório",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
        (value) => ( this.validEmail(value) ) || "Utilize um e-mail válido.",
      ],
      rulesEmail21: this.rules,

      mode: "save",
      user: {},
      users: [],
      booleanYes: Boolean,
      headers: [
        {
          text: "Código",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "E-mail",
          align: "start",
          sortable: true,
          value: "email",
        },
        {
          text: "Adminstrador",
          align: "start",
          sortable: false,
          value: "admin",
          formatter: this.change,
        },
        {
          text: "Acões",
          align: "start",
          value: "action",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    loadUsers() {
      const url = `${baseApiUrl}/users`;
      axios.get(url).then((res) => {
        this.users = res.data;
      });
    },
    change(value) {
      let simOuNao = "";
      if (value) {
        simOuNao = "Sim";
      } else simOuNao = "Não";
      return simOuNao;
    },
    reset() {
      this.mode = "save";
      this.user = {};
      this.loadUsers();
    },
    save() {
      const method = this.user.id ? "put" : "post";
      const id = this.user.id ? `/${this.user.id}` : "";
      axios[method](`${baseApiUrl}/users${id}`, this.user)
        .then(() => {
          showSucess();
          this.reset();
        })
        .catch(showError);
    },
    remove() {
      const id = this.user.id;
      axios
        .delete(`${baseApiUrl}/users/${id}`)
        .then(() => {
          showSucess();
          this.reset();
        })
        .catch(showError);
    },
    loadUser(user, mode = "save") {
      this.mode = mode;
      this.user = { ...user };
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      console.log(re.test(email));
      return re.test(email);
    },
  },
  mounted() {
    this.loadUsers();
    
  },
};
</script>

<style>
</style>